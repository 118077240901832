import React from 'react';
import styled from 'styled-components';
import { CheckIcon } from '@/assets/svg';
import { COLORS } from '@/constants';

export interface CheckboxProps {
  checked?: boolean;
  className?: string;
}

const Checkbox = ({
  className,
  checked,
  ...props
}: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Root className={className}>
      <Label tabIndex={-1}>
        <HiddenInput type="checkbox" tabIndex={0} {...props} />
        <CheckboxIcon>
          <Icon />
        </CheckboxIcon>
      </Label>
    </Root>
  );
};

const Root = styled.div`
  height: 16px;
  width: 16px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const HiddenInput = styled.input`
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip-path: inset(100%) !important;
  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
`;

const Label = styled.label`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled(CheckIcon)`
  width: 16px;
  height: 16px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const CheckboxIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.primaryBlue};
  border-radius: 6px;
  width: 16px;
  height: 16px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
  ${HiddenInput}:enabled + & {
    &:hover {
      border-color: ${COLORS.primaryBlue};
    }
  }

  ${HiddenInput}:checked + & {
    background: ${COLORS.primaryBlue};
    border: none;
    &:hover {
      background: ${COLORS.primaryDarkBlue};
    }
    ${Icon} {
      display: flex;
    }
  }

  ${HiddenInput}:focus + & {
    box-shadow: 0 0 0 2px ${COLORS.lightBlue};
  }
  ${HiddenInput}:focus:not(:checked) + & {
    box-shadow: 0 0 0 2px ${COLORS.lightBlue};
  }
  @media (max-width: 768px) {
    svg {
      width: 12px;
      height: 10px;
    }
  }
`;

export default Checkbox;
