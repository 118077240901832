import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';
import { Container } from '@/components/Container';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

const Welcome: FC = () => {
  const { t } = useTranslation('welcome');

  return (
    <section id="top">
      <StyledContainer>
        <Title>
          <Trans i18nKey="title" t={t}>
            <span>Новый уровень</span> <br /> взаимодействия с пациентом
          </Trans>
        </Title>
        <Subheading>
          <Trans i18nKey="description" t={t}>
            <span>
              Medico — платформа для сбора данных о самочувствии пациента с
              помощью персонализированных опросников. <br />
              Удаленное наблюдение за состоянием пациентов ещё никогда не было
              таким простым и эффективным.
            </span>
          </Trans>
        </Subheading>
        <ButtonWrapper>
          <StyledLinkRequest to="request" smooth={true} duration={500}>
            {t('request')}
          </StyledLinkRequest>
          <StyledLinkParticular to="particular" smooth={true} duration={500}>
            {t('particular')}
          </StyledLinkParticular>
        </ButtonWrapper>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 160px;
  padding-bottom: 120px;

  @media (max-width: 768px) {
    padding-top: 64px;
    padding-bottom: 80px;
    background: #fff;
  }
`;

const Title = styled.h1`
  ${TYPOGRAPHY.title1Bold64};
  text-align: center;
  color: ${COLORS.grayscaleDark};
  span {
    color: ${COLORS.primaryBlue};
  }
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2Bold44};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3Bold28};
  }
`;

const Subheading = styled.span`
  display: block;
  ${TYPOGRAPHY.bodyReg22};
  padding-top: 36px;
  padding-bottom: 64px;
  text-align: center;
  color: ${COLORS.grayscaleDark};
  @media (max-width: 768px) {
    padding-top: 16px;
    padding-bottom: 32px;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyReg16M};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledLinkRequest = styled(ScrollLink)`
  padding: 16px 30px;
  font-weight: 600;
  max-width: 184px;
  width: 100%;
  text-align: center;
  ${TYPOGRAPHY.buttonSemiBold14};
  color: ${COLORS.white};
  background-color: ${COLORS.primaryBlue};
  border-radius: 10px;
  cursor: pointer;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
  @media (max-width: 460px) {
    width: 100%;
    max-width: 100%;
  }
  &:hover {
    background-color: ${COLORS.primaryDarkBlue};
  }
`;

const StyledLinkParticular = styled(ScrollLink)`
  padding: 16px 30px;
  max-width: 184px;
  width: 100%;
  text-align: center;
  ${TYPOGRAPHY.buttonSemiBold14};
  color: ${COLORS.primaryBlue};
  background-color: ${COLORS.lightBlue};
  border-radius: 10px;
  cursor: pointer;
  @media (max-width: 460px) {
    width: 100%;
    max-width: 100%;
  }
  &:hover {
    background-color: ${COLORS.primaryAverage};
  }
`;

export default Welcome;
