import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/ui/components/Button';
import { COLORS, LINKS } from '@/constants';
import { Checkbox } from '@/ui/components/Checkbox';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { withFormik, FormikProps, FormikErrors, Form, Field } from 'formik';

interface MyFormProps {
  initialEmail?: string;
}

interface FormValues {
  email: string;
}

const EmailForm = (props: FormikProps<FormValues>) => {
  const { t } = useTranslation('common');
  const [isChecked, setIsChecked] = useState(false);
  const { touched, errors, isValid, isSubmitting } = props;

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Root>
      <Wrapper>
        {isSubmitting ? (
          <p>Ваш Email отправлен!</p>
        ) : (
          <StyledForm>
            <StyledEmailForm>
              <EmailInputWrapper>
                <StyledField
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  $hasError={!isValid}
                />
                {touched.email && errors.email && (
                  <div>
                    <ErrorText>Некорректный E-mail</ErrorText>
                  </div>
                )}
              </EmailInputWrapper>

              <StyledButton
                type="submit"
                theme="primary"
                disabled={!isValid || !isChecked}
              >
                {t('send')}
              </StyledButton>
            </StyledEmailForm>

            <Agreement>
              <StyledCheckbox checked={false} onChange={handleCheck} />
              <a href={LINKS.personalDataProcessingPolicy}>
                <span>Я согласен(на) </span>
                <AgreementLink>
                  политикой обработки персональных данных
                </AgreementLink>
              </a>
            </Agreement>
          </StyledForm>
        )}
      </Wrapper>
    </Root>
  );
};

const MyForm = withFormik<MyFormProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      email: props.initialEmail || '',
    };
  },

  validate: (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = 'Это обязательное поле';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Некорректный адрес';
    }
    return errors;
  },

  handleSubmit: (values) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append('entry.617420625', values.email);
    fetch(
      'https://docs.google.com/forms/d/e/1FAIpQLSfo9Ohwut3bIrpNpo7lUosBXGzqXimhR2so9A_-UosHQ8M23g/formResponse',
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
        redirect: 'follow',
      }
    ).then((response) => response.text());
  },
})(EmailForm);

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledForm = styled.div`
  width: 100%;
`;

const StyledButton = styled(Button)`
  max-width: 184px;
  width: 100%;
  @media (max-width: 768px) {
    max-width: none;
  }
`;

const StyledEmailForm = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Agreement = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: ${COLORS.grayscaleDarkmode};
  white-space: normal;
  a {
    line-height: 22px;
  }
  @media (min-width: 768px) {
    align-items: center;
  }
`;

const AgreementLink = styled.span`
  color: ${COLORS.primaryBlue};
  white-space: normal;
  border-bottom: 1px solid;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 12px;
`;

const EmailInputWrapper = styled.div`
  max-width: 388px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  @media (max-width: 768px) {
    max-width: none;
    margin-right: 0;
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledField = styled(Field)<{ $hasError: boolean }>`
  width: 100%;
  height: 50px;
  background-color: ${COLORS.lightBlue};
  ${({ $hasError }) =>
    $hasError &&
    `
    background-color: #FBECEF;
  `}

  box-shadow: 0px 1px 2px rgba(45, 45, 64, 0.05);
  border-radius: 10px;
  padding: 10px 12px;
  &:focus-visible {
    border: 1px solid ${COLORS.primaryDarkBlue};
    border-radius: 10px;
  }

  ${({ $hasError }) =>
    $hasError &&
    `
    &:focus-visible {
    border: 1px solid #DE5069;
    border-radius: 10px;
  }
  `}
  &:focus {
    outline: none;
  }
`;

const ErrorText = styled.span`
  color: #de5069;
  font-size: 12px;
  margin-top: 4px;
`;

export default MyForm;
