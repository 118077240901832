import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowDown } from '@/assets/svg';
import { Container } from '@/components/Container';
import { Line } from '@/assets/svg';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const EffectiveCommunication: FC = () => {
  const { t } = useTranslation('effectiveCommunication');

  return (
    <section>
      <StyledContainer>
        <Title>
          <Trans i18nKey="titleAdvantages" t={t}>
            <span>Новый уровень</span> <br /> взаимодействия с пациентом
          </Trans>
        </Title>

        <Subheading>
          <Trans i18nKey="subheading" t={t}>
            <span>
              Соберите всю необходимую информацию о состоянии пациента в три
              простых шага.
              <br />
              Максимальная эффективность при минимальных временных затратах.
            </span>
          </Trans>
        </Subheading>
        <HeadingStepWithArrow>
          <HeadingStep>
            <Trans i18nKey="step1" t={t}>
              <span>Шаг 1.</span> <br /> Создайте опросник
            </Trans>
          </HeadingStep>
        </HeadingStepWithArrow>
        <ContentInner>
          <Content>
            <Icon>
              <img
                src="/images/template-library.svg"
                alt={t('advantage1Heading')}
              />
            </Icon>

            <Heading>
              <Trans i18nKey="advantage1Heading" t={t}>
                Библиотека шаблонов
              </Trans>
            </Heading>

            <Description>
              <Trans i18nKey="advantage1Description" t={t}>
                Выбирайте и комбинируйте шаблоны,
                <br /> а также создавайте новые и сохраняйте <br />
                их в персональную библиотеку.
              </Trans>
            </Description>
          </Content>
          <div>
            <StaticImage
              src="../../../images/template-library.png"
              alt="Template Library"
            />
          </div>
        </ContentInner>

        <ContentInnerRight>
          <div className="img_left">
            <StaticImage
              src="../../../images/customization.png"
              alt="Customization"
            />
          </div>
          <ContentRight>
            <Icon>
              <img
                src="/images/customization.svg"
                alt={t('advantage2Heading')}
              />
            </Icon>
            <Heading>
              <Trans i18nKey="advantage2Heading" t={t}>
                Безграничная кастомизация
              </Trans>
            </Heading>

            <Description>
              <Trans i18nKey="advantage2Description" t={t}>
                <span>
                  Вы можете добавить или удалить любой
                  <br />
                  из готовых вопросов. Если их недостаточно,
                  <br />
                  создавайте и сохраняйте свои.
                </span>
              </Trans>
            </Description>
          </ContentRight>
        </ContentInnerRight>
        <HeadingStepWithArrow>
          <HeadingStep>
            <Trans i18nKey="step2" t={t}>
              <span>Шаг 2.</span> <br />
              Настройте уведомления
            </Trans>
          </HeadingStep>
        </HeadingStepWithArrow>

        <ContentInner>
          <Content>
            <Icon>
              <img src="/images/lvls.svg" alt={t('advantage3Heading')} />
            </Icon>
            <Heading>
              <Trans i18nKey="advantage3Heading" t={t}>
                Критические уровни
              </Trans>
            </Heading>
            <Description>
              <Trans i18nKey="advantage3Description" t={t}>
                Устанавливайте критические значения <br /> для каждого
                показателя, и приложение
                <br /> мгновенно уведомит об их достижении.
              </Trans>
            </Description>
          </Content>
          <div>
            <StaticImage src="../../../images/lvls.png" alt="lvls" />
          </div>
        </ContentInner>

        <ContentInnerRight>
          <div className="img_left">
            <StaticImage src="../../../images/sending.png" alt="sending" />
          </div>
          <ContentRight>
            <Icon>
              <img src="/images/sending.svg" alt={t('advantage4Heading')} />
            </Icon>
            <Heading>
              <Trans i18nKey="advantage4Heading" t={t}>
                Автоматическая отправка
              </Trans>
            </Heading>
            <Description>
              <Trans i18nKey="advantage4Description" t={t}>
                <span>
                  Опросник будет самостоятельно <br /> отправляться пациенту с
                  заданной <br /> периодичностью, а все результаты
                  <br /> сохранятся в индивидуальном профиле.
                </span>
              </Trans>
            </Description>
          </ContentRight>
        </ContentInnerRight>
        <HeadingStepWithArrow>
          <HeadingStep>
            <Trans i18nKey="step3" t={t}>
              <span>Шаг 3. </span> <br /> Проанализируйте результаты
            </Trans>
          </HeadingStep>
        </HeadingStepWithArrow>

        <ContentInner>
          <Content>
            <Icon>
              <img src="/images/analysis.svg" alt={t('advantage5Heading')} />
            </Icon>
            <Heading>
              <Trans i18nKey="advantage5Heading" t={t}>
                Удобство анализа
              </Trans>
            </Heading>
            <Description>
              <Trans i18nKey="advantage5Description" t={t}>
                Изучайте ответы пациентов намного быстрее <br />
                благодаря продуманному отображению данных.
              </Trans>
            </Description>
          </Content>
          <div className="img_right">
            <StaticImage src="../../../images/analysis.png" alt="analysis" />
          </div>
        </ContentInner>
      </StyledContainer>
    </section>
  );
};

const HeadingStepWithArrow = ({ children }) => {
  return (
    <>
      <StyledArrowDown>
        <ArrowDown />
      </StyledArrowDown>
      {children}
      <StyledLine>
        <Line />
      </StyledLine>
    </>
  );
};

const StyledArrowDown = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 50px;
    height: 173px;
  }
  @media (max-width: 768px) {
    svg {
      width: 48px;
      height: 122px;
    }
  }
`;

const StyledLine = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 10px;
    height: 49px;
  }
  @media (max-width: 768px) {
    svg {
      width: 8px;
      height: 40px;
    }
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 70px;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 60px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1Bold64};
  text-align: center;
  color: ${COLORS.grayscaleDark};
  span {
    color: ${COLORS.primaryBlue};
  }
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2Bold44};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3Bold28};
  }
`;

const Subheading = styled.div`
  ${TYPOGRAPHY.bodyReg22};
  padding-top: 36px;
  padding-bottom: 120px;
  text-align: center;
  color: ${COLORS.grayscaleDark};
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyReg16M};
    padding-bottom: 48px;
  }
`;

const HeadingStep = styled.h2`
  ${TYPOGRAPHY.title3Bold28};
  padding-top: 34px;
  padding-bottom: 34px;
  text-align: center;
  color: ${COLORS.grayscaleDark};
  span {
    color: ${COLORS.primaryBlue};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyBold22M};
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media (min-width: 768px) {
    br {
      display: none;
    }
  }
`;

const Heading = styled.h2`
  ${TYPOGRAPHY.title2Bold44};
  margin-bottom: 22px;
  text-align: left;
  color: ${COLORS.grayscaleDark};
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title3Bold28};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyBold22M};
    margin-bottom: 16px;
  }
`;

const Description = styled.div`
  ${TYPOGRAPHY.bodyReg22};
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.bodyReg18};
  }
  color: ${COLORS.grayscaleDark};
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyReg16M};
  }
`;

const ContentInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
  padding-top: 40px;
  margin-bottom: 100px;

  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    margin-bottom: 10px;
  }
`;

const ContentInnerRight = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
  padding-top: 40px;
  margin-bottom: 100px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    padding-top: 70px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 86px;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 32px;
  }
`;

const Icon = styled.div`
  margin-bottom: 34px;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export default EffectiveCommunication;
