import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from '@/components/Container';
import { MyForm } from '@/components/Form';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Request: FC = () => {
  const { t } = useTranslation('request');
  return (
    <section id="request">
      <StyledContainer>
        <div>
          <Title>
            <Trans i18nKey="titleRequest" t={t}>
              <span>
                Начните прямо<Highlight> сейчас</Highlight>
              </span>
            </Trans>
          </Title>
          <Subheading>
            <Trans i18nKey="subheading" t={t}>
              <span>
                Оставьте заявку на подключение к системе Medico и получите
                индивидуальное коммерческое предложение.
              </span>
            </Trans>
          </Subheading>

          <MyForm />

          <PrefooterImages>
            <StaticImage src="../../../images/prefooter.png" alt="preview" />
          </PrefooterImages>
        </div>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 120px;
  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1Bold64};
  text-align: center;
  color: ${COLORS.grayscaleDark};
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2Bold44};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3Bold28};
  }
`;

const Highlight = styled.span`
  color: ${COLORS.primaryBlue};
`;

const Subheading = styled.p`
  ${TYPOGRAPHY.bodyReg22};
  padding-top: 36px;
  padding-bottom: 60px;
  text-align: center;
  color: ${COLORS.grayscaleDark};
  margin: 0 auto;
  max-width: 600px;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyReg16M};
  }
`;

const PrefooterImages = styled.div`
  padding-top: 60px;
`;

export default Request;
