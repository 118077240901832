import { EffectiveCommunication } from '@/pageComponents/mainPage/EffectiveCommunication';
import { Advantages } from '@/pageComponents/mainPage/Advantages';
import MainLayout from '@/layouts/MainLayout';
import { Particular } from '@/pageComponents/mainPage/Particular';
import React from 'react';
import { Request } from '@/pageComponents/mainPage/Request';
import { Seo } from '@/components/Seo';
import { Welcome } from '@/pageComponents/mainPage/Welcome';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

const Home = () => {
  const { t } = useI18next('seodata');

  const seoData = {
    metaDescription: t('metaDescription'),
    metaTitle: t('metaTitle'),
    keywords: t('keywords'),
  };

  return (
    <MainLayout>
      <Seo data={seoData} />
      <Welcome />
      <Particular />
      <EffectiveCommunication />
      <Advantages />
      <Request />
    </MainLayout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
