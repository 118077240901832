import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Container } from '@/components/Container';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Particular: FC = () => {
  const { t } = useTranslation('particular');

  return (
    <section id="particular">
      <StyledContainer>
        <Title>
          <Trans i18nKey="titleParticular" t={t}>
            <span>Новые стандарты</span> <br /> оценки состояния больных
          </Trans>
        </Title>

        <ItemsWrapper>
          <Item>
            <ItemIcon>
              <img
                src="images/full-contact.svg"
                alt={t('itemParticular1Heading')}
              />
            </ItemIcon>
            <ItemHeading>{t('itemParticular1Heading')}</ItemHeading>
            <ItemText>
              <Trans i18nKey="itemParticular1Text" t={t}>
                <span>
                  Всего несколько минут в день — и врач
                  <br /> всегда владеет полной информацией о самочувствии
                  пациента.
                </span>
              </Trans>
            </ItemText>
          </Item>
          <Item>
            <ItemIcon>
              <img
                src="images/universal.svg"
                alt={t('itemParticular1Heading')}
              />
            </ItemIcon>
            <ItemHeading>{t('itemParticular2Heading')}</ItemHeading>
            <ItemText>
              <Trans i18nKey="itemParticular2Text" t={t}>
                <span>
                  От одного визита до длительного лечения,
                  <br /> от общего самочувствия до мельчайших деталей — и всё
                  это в одном месте.
                </span>
              </Trans>
            </ItemText>
          </Item>
          <Item>
            <ItemIcon>
              <img src="images/safety.svg" alt={t('itemParticular1Heading')} />
            </ItemIcon>
            <ItemHeading>{t('itemParticular3Heading')}</ItemHeading>
            <ItemText>
              <Trans i18nKey="itemParticular3Text" t={t}>
                <span>
                  Будьте уверены в безопасности
                  <br /> как самого пациента, так и его данных — для
                  максимальной защиты они обезличены.
                </span>
              </Trans>
            </ItemText>
          </Item>
        </ItemsWrapper>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1Bold64};
  text-align: center;
  color: ${COLORS.grayscaleDark};
  span {
    color: ${COLORS.primaryBlue};
  }
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2Bold44};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3Bold28};
  }
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;

  padding-top: 120px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    row-gap: initial;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  align-content: center;
  margin-right: 20px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    margin-bottom: 64px;
    margin-right: 0;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const ItemIcon = styled.div`
  margin-bottom: 20px;
  min-height: 121px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    min-height: auto;
    margin-bottom: 12px;
  }
`;

const ItemHeading = styled.h3`
  ${TYPOGRAPHY.title3Bold28};
  color: ${COLORS.grayscaleDark};
  padding-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyBold22M};
  }
`;

const ItemText = styled.span`
  ${TYPOGRAPHY.bodyReg18};
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyReg16M};
  }
`;

export default Particular;
