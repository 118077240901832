import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Container } from '@/components/Container';
import styled from 'styled-components';

const Advantages: FC = () => {
  const { t } = useTranslation('advantages');

  return (
    <section id="advantages">
      <StyledContainer>
        <Title>
          <Trans i18nKey="titleBenefits" t={t}>
            <span>Преимущества </span> <br /> для каждого
          </Trans>
        </Title>
        <ItemsWrapper>
          <Item>
            <ItemIcon>
              <img src="/images/doctor.svg" alt={t('item1Heading')} />
            </ItemIcon>
            <ItemContent>
              <ItemHeading>{t('item1Heading')}</ItemHeading>
              <ItemText>
                <Trans i18nKey="item1Text" t={t}>
                  <span>
                    Постоянная уверенность в безопасности и хорошем самочувствии
                    пациента при минимальных временных затратах.
                  </span>
                </Trans>
              </ItemText>
            </ItemContent>
          </Item>
          <Item>
            <ItemIcon>
              <img src="/images/patient.svg" alt={t('item3Heading')} />
            </ItemIcon>
            <ItemContent>
              <ItemHeading>{t('item3Heading')}</ItemHeading>
              <ItemText>
                <Trans i18nKey="item3Text" t={t}>
                  <span>
                    Удобное приложение, позволяющее не беспокоиться о своем
                    состоянии, находясь под постоянным присмотром врача.
                  </span>
                </Trans>
              </ItemText>
            </ItemContent>
          </Item>
          <Item>
            <ItemIcon>
              <img src="/images/clinic.svg" alt={t('item2Heading')} />
            </ItemIcon>

            <ItemContent>
              <ItemHeading>{t('item2Heading')}</ItemHeading>
              <ItemText>
                <Trans i18nKey="item2Text" t={t}>
                  <span>
                    Забота о каждом пациенте, выраженная в персонализированном
                    подходе и тщательном наблюдении.
                  </span>
                </Trans>
              </ItemText>
            </ItemContent>
          </Item>

          <Item>
            <ItemIcon>
              <img src="/images/farma.svg" alt={t('item4Heading')} />
            </ItemIcon>
            <ItemContent>
              <ItemHeading>{t('item4Heading')}</ItemHeading>
              <ItemText>
                <Trans i18nKey="item4Text" t={t}>
                  <span>
                    Универсальный инструмент, идеально подходящий для проведения
                    клинических исследований.
                  </span>
                </Trans>
              </ItemText>
            </ItemContent>
          </Item>
        </ItemsWrapper>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-bottom: 80px;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1Bold64};
  text-align: center;
  color: ${COLORS.primaryBlue};

  span {
    color: ${COLORS.grayscaleDark};
  }

  @media (min-width: 768px) {
    br {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title2Bold44};
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3Bold28};
  }
`;

const Highlight = styled.span`
  color: ${COLORS.primaryBlue};
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 90px;
  gap: 64px 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Item = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemIcon = styled.div`
  margin-right: 62px;
  @media (max-width: 1200px) {
    margin-right: 30px;
  }
  @media (max-width: 768px) {
    margin-bottom: 24px;
    margin-right: 0;
  }
`;

const ItemHeading = styled.h3`
  ${TYPOGRAPHY.title3Bold28};
  color: ${COLORS.grayscaleDark};
  padding-bottom: 20px;
`;

const ItemText = styled.span`
  ${TYPOGRAPHY.bodyReg18};
  max-width: 344px;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.bodyReg16M};
  }
`;

export default Advantages;
